<template>
  <div class="payments" v-if="hasPerm('cash.view_cashlog')">
    <page-header title="Caisse" icon="fa fa-piggy-bank" :links="links"></page-header>
    <div class="sub-header">
      <b-row>
        <b-col cols="2">
          <b-form-group label="Mode de paiement" label-for="payment-modes">
            <b-form-select
              id="payment-modes"
              v-model="selectedPaymentMode"
              @change="onPaymentModeSelected"
              :disabled="paymentModes.length < 2"
              >
              <b-form-select-option
                :value="item"
                v-for="item in paymentModes"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <date-range-selector
            :free-period="true"
            :has-today="true"
            :init-with-last-school-year="true"
            @change="onDateRangeChanged">
          </date-range-selector>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Filtrer par" label-for="showHour" v-if="isFreePeriod">
            <b-checkbox v-model="showHour" id="showHour" @change="onHourChanged">
              Heure
            </b-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-checkbox
            :checked="noPagination"
            :disabled="(pagesCount > 10) || isLoading(loadingName)"
            @change="reloadPagination"
            style="margin-bottom: 5px"
          >
            Tout voir
          </b-checkbox>
          <pagination
            :pages-count="pagesCount"
            :has-next="hasNext"
            :has-previous="hasPrevious"
            @change="onPageChanged($event)"
            css-style="justify-content: flex-end;"
          >
          </pagination>
        </b-col>
      </b-row>
      <b-row v-if="showHour">
        <b-col cols="2">
        </b-col>
        <b-col cols="5">
          <b-row>
            <b-col cols="4"></b-col>
            <b-col cols="4">
              <b-form-group
                label="Heure" label-for="hour1"
              >
                <b-form-input
                  id="hour1"
                  v-model="hour1"
                  type="time"
                  :disabled="!startDate"
                  @change="onHourChanged"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Heure" label-for="hour2"
              >
                <b-form-input
                  id="hour1"
                  v-model="hour2"
                  type="time"
                  :disabled="!endDate"
                  @change="onHourChanged"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b-form-group label="Dépôt" label-for="deposit-modes">
            <b-form-select
              id="deposit-modes"
              v-model="selectedDepositMode"
              @change="onDepositModeSelected"
              >
              <b-form-select-option
                :value="item"
                v-for="item in depositModes"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Type" label-for="cash-types">
            <b-form-select
              id="cash-types"
              v-model="selectedCashType"
              @change="onCashTypeSelected"
              >
              <b-form-select-option
                :value="item"
                v-for="item in cashTypes"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Recherche" label-for="search">
            <b-input-group>
              <b-form-input
                id="search"
                type="search"
                placeholder="Texte inclus dans le libellé"
                v-model="search"
                autocomplete="off"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button type="submit" variant="primary" @click.prevent="onSearch">
                  <i class="fa fa-search"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Recherche par montant" label-for="search-amount">
            <b-input-group>
              <decimal-input id="search-amount" v-model="searchAmount" allow-null></decimal-input>
              <b-input-group-append>
                <b-button type="submit" variant="primary" @click.prevent="onSearch">
                  <i class="fa fa-search"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="" label-for="onlyAnalytics">
            <b-checkbox id="onlyAnalytics" v-model="onlyAnalytics">
              Par analytiques
            </b-checkbox>
          </b-form-group>
          <b-form-group label="" label-for="orderAsc">
            <b-checkbox id="orderAsc" v-model="orderAsc">
              Ordre chronologique
            </b-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div class="empty-text" v-if="initialLoadingDone && !isLoading(loadingName) && cashLogsCount === 0">
      Aucune entrée de caisse
    </div>
    <div ref="excelTable">
      <div v-if="!isLoading(loadingName) && initialLoadingDone" ref="printMe">
        <table class="table table-bordered" v-if="!onlyAnalytics && cashLogs.length" style="width: 100%;">
          <tr class="tr-bigger">
            <th>Total {{ selectedPaymentMode.name }}</th>
            <th colspan="2" class="number">{{ cashLogsTotal | currency }}</th>
            <td colspan="3">
              <div class="small2">
                Montant total depuis la création de la caisse jusqu'à aujourd'hui
              </div>
            </td>
            <td colspan="3" class="text-right">
              <div class="small2">
                <counter-label :counter="cashLogsCount" label="flux" label-n="flux"></counter-label>
                sur la période
              </div>
            </td>
          </tr>
          <tr class="tr-bigger" v-if="endDate">
            <th>
              En date du {{ endDate | dateToString('DD/MM/YYYY') }}
              <span v-if="showHour && hour2">{{ hour2 }}</span>
            </th>
            <th colspan="2" class="number">{{ cashLogsEndDate | currency }}</th>
            <td colspan="3">
              <div class="small2">
                Montant total depuis la création de la caisse jusqu'au
               {{ endDate | dateToString('DD/MM/YYYY') }}
                <span v-if="showHour && hour2">{{ hour2 }}</span>
              </div>
            </td>
            <td colspan="3" class="text-right">
              <div class="small2">
                Du {{ startDate | dateToString('DD/MM/YYYY') }}
                <span v-if="showHour && hour1">{{ hour1 }}</span>
                au {{ endDate | dateToString('DD/MM/YYYY') }}
                <span v-if="showHour && hour2">{{ hour2 }}</span>
              </div>
            </td>
          </tr>
          <tr class="tr-bigger" v-else>
            <td></td>
            <td></td>
            <td></td>
            <td colspan="4"></td>
            <td colspan="4" class="text-right">
              <div class="small2">
                Du {{ startDate | dateToString('DD/MM/YYYY') }} au {{ endDate | dateToString('DD/MM/YYYY') }}
              </div>
            </td>
          </tr>
        </table>
        <table class="table table-bordered small" v-if="cashLogs.length" style="width: 100%;">
          <tr>
            <th>Date</th>
            <th v-if="onlyAnalytics">Saisie</th>
            <th>Libellé et compte</th>
            <th v-if="!onlyAnalytics">Dépôt</th>
            <th v-if="!onlyAnalytics">Dépense</th>
            <th v-if="!onlyAnalytics">Recette</th>
            <th>N° Pièce</th>
            <th>Analytique</th>
            <th>Général</th>
            <th>Année</th>
            <th>Montant</th>
            <th class="ignore" v-if="!onlyAnalytics && canDelete"></th>
          </tr>
          <tr v-if="!onlyAnalytics">
            <td></td>
            <td colspan="2" class="text-right"><b>Solde de la période: </b></td>
            <th colspan="2" class="number" style="text-align: center">{{ totalPeriod | currency }}</th>
            <td colspan="4"></td>
            <td class="ignore" v-if="canDelete"></td>
          </tr>
          <tr v-if="!onlyAnalytics">
            <td colspan="2"></td>
            <td>
              <b-checkbox
                :checked="isAllSelectedForDeposit()"
                :value="true"
                :unchecked-value="false"
                @change="toggleAllForDeposit()"
                v-if="hasNotDeposed()"
              ></b-checkbox>
            </td>
            <th class="number">{{ expensesPeriod | currency }}</th>
            <th class="number">{{ paymentsPeriod | currency }}</th>
            <td colspan="5"></td>
            <td class="ignore" v-if="canDelete"></td>
          </tr>
          <tr
            v-for="line in lines"
            :key="line.index"
            :class="{ even: !line.odd, transfer: line.isTransferBank, refund: line.isRefund, expense: line.isExpense, }"
          >
            <td
              v-if="line.item"
              :rowspan="line.linesCount"
              :title="creationInfo(line)"
              v-b-tooltip
              :class="{bold: diffDate(line)}"
            >
              {{ line.item.logDate | dateToString }}
            </td>
            <td v-if="onlyAnalytics">
              <span v-if="diffDate(line)">{{ line.item.createdOn | dateToString }}</span>
            </td>
            <td v-if="line.item" :rowspan="line.linesCount">
              <div v-if="!line.item.entity.isOwn">
                <router-link :to="getEntityLink(line.item.entity)">
                  {{ line.item.entity.name }}
                </router-link>
              </div>
              {{ line.item.label }}
              <div class="small2" v-if="line.item.bankName || line.item.bankNumber">
                {{ line.item.bankName }} {{ line.item.bankNumber }}
              </div>
              <div class="small2" v-if="line.item.emitter">
                {{ line.item.emitter }}
              </div>
              <div v-if="line.isRefund">Remboursement</div>
            </td>
            <td v-if="line.item && !onlyAnalytics" :rowspan="line.linesCount">
              <span v-if="line.item.deposit">
                <router-link :to="{ name: 'deposit-detail', params: { depositId: line.item.deposit.id, }, }">
                  {{ line.item.deposit.number }} {{ line.item.deposit.depositOn | dateToString }}
                </router-link>
              </span>
              <span v-else>
                 <b-checkbox
                  :checked="isSelectedForDeposit(line.item)"
                  :value="true"
                  :unchecked-value="false"
                  @change="toggleForDeposit(line.item)"
                ></b-checkbox>
              </span>
            </td>
            <td class="number" v-if="line.item && !onlyAnalytics" :rowspan="line.linesCount">
              <span v-if="line.isExpense">
                {{ line.item.amount | currency }}
              </span>
            </td>
            <td class="number" v-if="line.item && !onlyAnalytics" :rowspan="line.linesCount">
              <span v-if="line.isPayment">
                {{ line.item.amount | currency }}
              </span>
            </td>
            <td>
              {{ line.reference }}
            </td>
            <td v-if="line.isTransferBank">
              Dépôt en banque
            </td>
            <td v-if="!line.isTransferBank">
              <span v-if="line.analytic">{{ line.analytic.analyticAccount.name }}</span>
            </td>
            <td>
              <span v-if="line.analytic">{{ line.analytic.generalAccount.name }}</span>
            </td>
            <td>
              <span v-if="line.analytic">{{ line.analytic.schoolYear.name }}</span>
            </td>
            <td class="number">
              <span v-if="line.analytic">
                <span v-if="line.isExpense">{{ -line.analytic.amount | currency }}</span>
                <span v-else>{{ line.analytic.amount | currency }}</span>
              </span>
            </td>
            <td v-if="canDelete && !onlyAnalytics" class="ignore">
              <a
                href
                @click.prevent="deleteCashLog(line.item)"
                v-if="line.item && line.item.entity.isOwn && !line.item.deposit"
              >
                <i class="fa fa-close" v-b-tooltip="'Suppression'"></i>
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <cash-modal
      @done="onRefresh"
      :all-analytic-accounts="analyticAccounts"
      :all-general-accounts="generalAccounts"
      :all-payment-modes="paymentModes"
      :transferBank="transferBank"
    ></cash-modal>
    <transfer-cash-modal
      @done="onRefresh"
      :all-analytic-accounts="analyticAccounts"
      :all-general-accounts="generalAccounts"
      :all-payment-modes="paymentModes"
    ></transfer-cash-modal>
    <delete-cash-modal
      @done="onRefresh"
      :cashLog="selectedCashLog"
    ></delete-cash-modal>
    <create-deposit-modal
      id="bv-create-deposit"
      :payments="depositPayments"
      :expenses="depositExpenses"
      :refunds="depositRefunds"
      @deposit="onRefresh()"
    ></create-deposit-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import * as moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import DateRangeSelector from '@/components/DateRange/DateRangeSelector'
import LoadingGif from '@/components/Controls/LoadingGif'
import Pagination from '@/components/Controls/Pagination'
import CashModal from '@/components/Cash/CashModal'
import TransferCashModal from '@/components/Cash/TransferCashModal.vue'
import DeleteCashModal from '@/components/Cash/DeleteCashModal'
import CounterLabel from '@/components/Controls/CounterLabel'
import DecimalInput from '@/components/Controls/DecimalInput'
import PageHeader from '@/components/Layout/PageHeader'
import CreateDepositModal from '@/components/Deposits/CreateDepositModal'
import { makeChoice } from '@/types/base'
import { makeCashLog } from '@/types/cash'
import { makeAnalyticAccount, makeGeneralAccount, makePaymentMode } from '@/types/payments'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'
import { dateToString } from '@/filters/texts'

export default {
  name: 'Payments',
  mixins: [BackendMixin],
  components: {
    DecimalInput,
    CreateDepositModal,
    CounterLabel,
    DeleteCashModal,
    Pagination,
    LoadingGif,
    PageHeader,
    CashModal,
    TransferCashModal,
    DateRangeSelector,
  },
  data() {
    return {
      loadingName: 'cashLogs',
      initialLoadingDone: false,
      analyticAccounts: [],
      generalAccounts: [],
      schoolYears: [],
      cashLogs: [],
      cashLogsCount: 0,
      page: 0,
      cashLogsTotal: 0,
      cashInitialAmount: 0,
      cashInitialDate: '',
      cashLogsEndDate: 0,
      paymentsPeriod: 0,
      expensesPeriod: 0,
      totalPeriod: 0,
      hasNext: false,
      hasPrevious: false,
      selectedCashLog: null,
      startDate: null,
      endDate: null,
      noPagination: false,
      paymentModes: [],
      selectedPaymentMode: null,
      transferBank: false,
      cashTypes: [
        makeChoice({ id: 0, name: 'Tous', }),
        makeChoice({ id: 1, name: 'Dépenses', }),
        makeChoice({ id: 2, name: 'Paiements', })
      ],
      depositModes: [
        makeChoice({ id: 0, name: 'Tous', }),
        makeChoice({ id: 1, name: 'À déposer', }),
        makeChoice({ id: 2, name: 'Déjà déposé', })
      ],
      selectedDepositMode: null,
      selectedCashType: null,
      depositSelection: [],
      search: '',
      searchAmount: null,
      hour1: null,
      hour2: null,
      showHour: false,
      isFreePeriod: false,
      onlyAnalytics: false,
      orderAsc: false,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
    orderAsc: function(newValue, oldValue) { this.reload() },
  },
  computed: {
    lines() {
      let lines = []
      let odd = true
      for (const line of this.cashLogs) {
        const elt = {
          item: line,
          analytic: line.analytics.length ? line.analytics[0] : null,
          index: lines.length,
          linesCount: this.onlyAnalytics ? 1 : (line.analytics.length || 1),
          isExpense: line.isExpense,
          isPayment: line.isPayment,
          isRefund: line.isRefund,
          isTransferBank: line.isTransferBank,
          reference: line.reference,
          odd: odd,
        }
        lines.push(elt)
        for (let index = 1; index < line.analytics.length; index++) {
          const subElt = {
            item: this.onlyAnalytics ? line : null,
            analytic: line.analytics[index],
            index: lines.length,
            linesCount: this.onlyAnalytics ? 1 : 0,
            isExpense: line.isExpense,
            isPayment: line.isPayment,
            isRefund: line.isRefund,
            isTransferBank: false,
            odd: odd,
          }
          lines.push(subElt)
        }
        odd = !odd
      }
      return lines
    },
    pagesCount() {
      if (this.noPagination) {
        return 1
      }
      return Math.ceil(this.cashLogsCount / 100)
    },
    canDelete() {
      return this.hasPerm('cash.delete_cashlog')
    },
    depositPayments() {
      return this.cashLogs.filter(
        item => item.isPayment && !item.deposit && this.isSelectedForDeposit(item)
      ).map(elt => elt.payment)
    },
    depositExpenses() {
      return this.cashLogs.filter(
        item => item.isExpense && !item.isRefund && !item.deposit && this.isSelectedForDeposit(item)
      ).map(elt => elt.expense)
    },
    depositRefunds() {
      return this.cashLogs.filter(
        item => item.isRefund && !item.deposit && this.isSelectedForDeposit(item)
      ).map(elt => elt.refund)
    },
    links() {
      let links = []
      if (this.hasPerm('cash.add_cashlog') && this.paymentModes.length) {
        links = [
          {
            id: 4,
            label: 'PDF',
            callback: this.printMe,
            icon: 'fa fa-file-pdf',
            cssClass: (this.isLoading(this.loadingName)) ? 'btn-secondary disabled' : 'btn-secondary',
          },
          {
            id: 3,
            label: 'Excel',
            callback: this.excelMe,
            icon: 'fa fa-file-excel',
            cssClass: (this.isLoading(this.loadingName)) ? 'btn-secondary disabled' : 'btn-secondary',
          },
          {
            id: 2,
            label: 'Dépôt en banque',
            callback: this.addTransfer,
            icon: 'fa fa-bank',
            cssClass: 'btn-secondary',
          },
          {
            id: 5,
            label: 'Déposer',
            callback: this.createDeposit,
            icon: 'fas fa-money-bill-alt',
            cssClass: (this.depositSelection.length > 0) ? 'btn-primary' : 'btn-primary disabled',
          }
        ]
        if (this.paymentModes.length > 1) {
          links.push(
            {
              id: 6,
              label: 'Transférer',
              callback: this.transferCash,
              icon: 'fa fa-exchange',
              cssClass: 'btn-secondary',
            }
          )
        }
        links.push(
          {
            id: 1,
            label: 'Ajouter',
            callback: this.addEntry,
            icon: 'fa fa-piggy-bank',
            cssClass: 'btn-primary',
          }
        )
      }
      return links
    },
  },
  async mounted() {
    this.selectedCashType = this.cashTypes[0]
    this.selectedDepositMode = this.depositModes[0]
    await this.loadPaymentModes()
    await this.loadGeneralAccounts()
    await this.loadAnalyticAccounts()
    await this.loadSchoolYears()
    await this.onLoaded()
    await this.onRefresh()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      this.selectedCashLog = null
      this.init = true
      if (this.$route.query) {
        this.page = +(this.$route.query.page || '1')
      } else {
        this.page = 1
      }
      this.init = false
    },
    onRefresh(event) {
      this.page = 1
      this.reload()
    },
    onPageChanged(event) {
      this.page = event.page
      if (this.initialLoadingDone) {
        this.loadElements(this.page)
      }
    },
    addDataFilterToUrl(url, noDate = false, endDate = false) {
      if (this.startDate && !noDate) {
        url += '&start_date=' + moment(this.startDate).format('YYYY-MM-DD')
        if (this.hour1) {
          url += '&hour1=' + this.hour1
        }
      }
      if (this.endDate && (!noDate || endDate)) {
        url += '&end_date=' + moment(this.endDate).format('YYYY-MM-DD')
        if (this.hour2) {
          url += '&hour2=' + this.hour2
        }
      }
      if (this.selectedPaymentMode) {
        url += '&payment_mode=' + this.selectedPaymentMode.id
      }
      if (this.selectedCashType) {
        url += '&type=' + this.selectedCashType.id
      }
      if (this.selectedDepositMode) {
        url += '&deposit=' + this.selectedDepositMode.id
      }
      if (this.search) {
        url += '&search=' + encodeURI(this.search)
      }
      if (this.searchAmount) {
        url += '&amount=' + encodeURI('' + this.searchAmount)
      }
      return url
    },
    async loadElements(page) {
      let url = '/api/cash-logs/?page=' + (page || 1)
      url = this.addDataFilterToUrl(url)
      if (this.noPagination) {
        url += '&no_page=1'
      }
      if (this.orderAsc) {
        url += '&asc=1'
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.initialLoadingDone = true
        if (this.noPagination) {
          this.cashLogsCount = resp.data.length
          this.hasNext = false
          this.hasPrevious = false
          this.cashLogs = resp.data.map(elt => makeCashLog(elt))
        } else {
          this.cashLogsCount = resp.data.count
          this.hasNext = !!resp.data.next
          this.hasPrevious = !!resp.data.previous
          this.cashLogs = resp.data.results.map(elt => makeCashLog(elt))
        }
        for (const elt of this.cashLogs) {
          if (elt.payment) {
            elt.payment.entity = elt.entity
          }
          if (elt.refund) {
            elt.refund.entity = elt.entity
          }
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSum() {
      const baseUrl = '/api/cash-logs/sum/?'
      const url1 = this.addDataFilterToUrl(baseUrl, true)
      // Pour le montant en caisse, on ne prend pas en compte la date car il
      // faut prendre en compte tous les flux depuis le début
      const backendApi1 = new BackendApi('get', url1)
      try {
        const resp = await backendApi1.callApi()
        this.cashLogsTotal = (resp.data.sum || 0) + (resp.data.initial || 0)
        this.cashInitialAmount = resp.data.initial || 0
        this.cashInitialDate = resp.data['initial_date'] || ''
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }

      if (this.endDate) {
        const url3 = this.addDataFilterToUrl(baseUrl, true, true)
        // montant en fin de période
        const backendApi3 = new BackendApi('get', url3)
        try {
          const resp = await backendApi3.callApi()
          this.cashLogsEndDate = (resp.data.sum || 0) + (resp.data.initial || 0)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      } else {
        this.cashLogsEndDate = 0
      }

      const url2 = this.addDataFilterToUrl(baseUrl, false)
      // Pour la somme sur période, on prend seulement sur la période
      const backendApi2 = new BackendApi('get', url2)
      try {
        const resp = await backendApi2.callApi()
        this.totalPeriod = resp.data.sum || 0
        this.paymentsPeriod = resp.data.payments || 0
        this.expensesPeriod = resp.data.expenses || 0
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    addEntry() {
      this.transferBank = false
      this.$bvModal.show('bv-modal-cash')
    },
    addTransfer() {
      this.transferBank = true
      this.$bvModal.show('bv-modal-cash')
    },
    transferCash() {
      this.transferBank = true
      this.$bvModal.show('bv-modal-transfer-cash')
    },
    createDeposit() {
      this.$bvModal.show('bv-create-deposit')
    },
    async loadAnalyticAccounts() {
      const backendApi = new BackendApi('get', '/api/analytic-accounts/')
      try {
        let resp = await backendApi.callApi()
        this.analyticAccounts = resp.data.map(elt => makeAnalyticAccount(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadGeneralAccounts() {
      const backendApi = new BackendApi('get', '/api/general-accounts/')
      try {
        let resp = await backendApi.callApi()
        this.generalAccounts = resp.data.map(elt => makeGeneralAccount(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getAnalyticAccount(line) {
      const matching = this.analyticAccounts.filter(elt => elt.id === line.analytic)
      if (matching.length) {
        return matching[0].name
      }
      return ''
    },
    getGeneralAccount(line) {
      const matching = this.generalAccounts.filter(elt => elt.id === line.general)
      if (matching.length) {
        return matching[0].name + ' - ' + matching[0].label
      }
      return ''
    },
    getSchoolYear(line) {
      const matching = this.schoolYears.filter(elt => elt.id === line.schoolYear)
      if (matching.length) {
        return matching[0].name
      }
      return ''
    },
    getEntityLink(entity) {
      if (entity.family) {
        return { name: 'families-detail', params: { entityId: '' + entity.id, }, }
      } else {
        return { name: 'entities-detail', params: { entityId: '' + entity.id, }, }
      }
    },
    deleteCashLog(cashLog) {
      this.selectedCashLog = cashLog
      setTimeout(
        () => {
          this.$bvModal.show('bv-modal-delete-cash')
        },
        100
      )
    },
    async reload() {
      this.startLoading(this.loadingName)
      if (this.selectedPaymentMode) {
        await this.loadSum()
        await this.loadElements(this.page)
      }
      this.endLoading(this.loadingName)
    },
    async onPaymentModeSelected() {
      await this.reload()
    },
    async onDepositModeSelected() {
      await this.reload()
    },
    async onCashTypeSelected() {
      await this.reload()
    },
    async onSearch() {
      await this.reload()
    },
    async onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.isFreePeriod = event.isFreePeriod
      if (!this.isFreePeriod) {
        this.showHour = false
      }
      await this.reload()
    },
    onHourChanged() {
      if (this.showHour) {
        if ((this.startDate && this.hour1) || (this.endDate && this.hour2)) {
          this.reload()
        }
      } else {
        this.reload()
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const name = slugify(this.selectedPaymentMode.name)
      const docSlug = 'caisse-' + name + '-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      const name = slugify(this.selectedPaymentMode.name)
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'caisse-' + name + '-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    reloadPagination(value) {
      this.noPagination = value
      this.reload()
    },
    async loadPaymentModes() {
      try {
        let url = '/api/payment-modes/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.paymentModes = resp.data.map(elt => makePaymentMode(elt)).filter(elt => elt.isCash)
        if (this.paymentModes.length === 1) {
          this.selectedPaymentMode = this.paymentModes[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    isSelectedForDeposit(item) {
      return this.depositSelection.indexOf(item) >= 0
    },
    toggleForDeposit(item) {
      let index = this.depositSelection.indexOf(item)
      if (index >= 0) {
        this.depositSelection.splice(index, 1)
      } else {
        this.depositSelection.push(item)
      }
    },
    hasNotDeposed() {
      return this.cashLogs.filter(elt => !elt.deposit).length > 0
    },
    isAllSelectedForDeposit() {
      if (this.selectedDepositMode.length === 0) {
        return false
      }
      for (const item of this.cashLogs) {
        if (!item.deposit) {
          if (!this.isSelectedForDeposit(item)) {
            return false
          }
        }
      }
      return true
    },
    toggleAllForDeposit() {
      const isAllSelected = this.isAllSelectedForDeposit()
      const selection = []
      if (!isAllSelected) {
        for (const item of this.cashLogs) {
          if (!item.deposit) {
            selection.push(item)
          }
        }
      }
      this.depositSelection = selection
    },
    creationInfo(line) {
      let logDate = dateToString(line.item.logDate, 'DD/MM/YYYY HH:mm')
      let creation = dateToString(line.item.createdOn, 'DD/MM/YYYY HH:mm')
      let text = logDate
      if (line.item.createdBy) {
        text += ' saisi par ' + line.item.createdBy
      }
      if (logDate !== creation) {
        text += ' le ' + creation
      }
      return text
    },
    diffDate(line) {
      let logDate = dateToString(line.item.logDate, 'DD/MM/YYYY')
      let creation = dateToString(line.item.createdOn, 'DD/MM/YYYY')
      return (logDate !== creation)
    },
  },
}
</script>

<style scoped lang="less">
.header-line {
  padding: 5px;
  background: #e0e0e0;
  color: #000;
  border-bottom: none;
}
.header-label {
  font-size: 1.2em;
  font-weight: bold;
}
tr td .show-on-hover {
  display: none;
}
tr:hover td .show-on-hover {
  display: block;
  font-size: 11px;
}
table.table-transparent > tr > td{
  background: transparent !important;
}
.number {
  text-align: right;
}
.cell-like {
  padding: 3px;
  font-size: 11px;
}
tr th {
  background: #ccc;
}
tr.even td, tr.even th {
  background: #f0f0f0;
}
tr.expense td, tr.expense th {
  background: #ffe0e0;
}
tr.even.expense td, tr.even.expense th {
  background: #ffcccc;
}
tr.transfer td, tr.transfer th {
  background: #ffffd4;
}
tr.even.transfer td, tr.even.transfer th {
  background: #ffffa4;
}
tr.refund td, tr.refund th {
  background: #f2dcfd;
}
tr.even.refund td, tr.even.refund th {
  background: #efcfff;
}
tr.tr-bigger th, tr.tr-bigger td {
  background: #444;
  color: #fff;
}
</style>
